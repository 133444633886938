var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"press-page"},[_c('BreadcrumbsComponent',{attrs:{"title":"Пресс-центр","links":_vm.links}}),_c('div',{staticClass:"container-padding"},[_c('div',{staticClass:"press-page__inner page__inner"},[_c('div',{staticClass:"press-page__filter filters"},[_c('SelectComponent',{attrs:{"errors":_vm.form.type.errors,"options":_vm.news_types,"label-name":"title","title":"Тип новости","placeholder":"Все"},model:{value:(_vm.form.type.value),callback:function ($$v) {_vm.$set(_vm.form.type, "value", $$v)},expression:"form.type.value"}}),_c('InputComponent',{attrs:{"errors":_vm.form.title.errors,"title":"Поиск по заголовку"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('IconComponent',{attrs:{"name":"search-normal"}})]},proxy:true}]),model:{value:(_vm.form.title.value),callback:function ($$v) {_vm.$set(_vm.form.title, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.title.value"}}),_c('DateComponent',{attrs:{"emptyPlaceholder":"Любая","mode":"date","range":"","title":"Дата публикации"},model:{value:(_vm.form.published_at.value),callback:function ($$v) {_vm.$set(_vm.form.published_at, "value", $$v)},expression:"form.published_at.value"}}),_c('router-link',{staticClass:"btn btn--lg btn--outline",attrs:{"to":{
            name: 'news',
            query: {
              title: _vm.form.title.value,
              type: JSON.stringify(_vm.form.type.value),
              published_at: JSON.stringify(_vm.form.published_at.value),
            },
          }}},[_vm._v("\n          Поиск\n        ")])],1),_c('PressDisputableComponent'),_c('PressNewsComponent'),_c('PressAnnounceComponent'),_c('PressMediaComponent')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }