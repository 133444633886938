<template>
  <div class="press-page__news">
    <BaseArticle title="Новости" :route="{ name: 'news' }" />
    <div class="press-page__news-cols">
      <div class="press-page__news-col">
        <div class="press-page__news-title">Председатель</div>
        <ul v-if="minister_news && minister_news.length" class="press-page__news-body">
          <li v-for="(item, i) in minister_news" :key="i">
            <span>{{ item.title }}</span>
            <span>{{ item.published_at | formatDate }}</span>
          </li>
        </ul>
        <p v-else>В данный момент информация отсутствует</p>
        <router-link
          :to="{
            name: 'news',
            query: {
              type: JSON.stringify({ id: 1, title: 'Председатель', __typename: 'GovernmentNewsType' }),
            },
          }"
          class="press-page__news-action btn btn--lg btn--outline"
        >
          <span>Смотреть все</span>
          <IconComponent name="arrow-right" />
        </router-link>
      </div>
      <div class="press-page__news-col">
        <div class="press-page__news-title">Правительство</div>
        <ul v-if="department_news && department_news.length" class="press-page__news-body">
          <li v-for="(item, i) in department_news" :key="i">
            <span>{{ item.title }}</span>
            <span>{{ item.published_at | formatDate }}</span>
          </li>
        </ul>
        <p v-else>В данный момент информация отсутствует</p>
        <router-link
          :to="{
            name: 'news',
            query: {
              type: JSON.stringify({ id: 2, title: 'Правительство', __typename: 'GovernmentNewsType' }),
            },
          }"
          class="press-page__news-action btn btn--lg btn--outline"
        >
          <span>Смотреть все</span>
          <IconComponent name="arrow-right" />
        </router-link>
      </div>
      <div class="press-page__news-col">
        <div class="press-page__news-title">Другие новости</div>
        <ul v-if="exception_news && exception_news.length" class="press-page__news-body">
          <li v-for="(item, i) in exception_news" :key="i">
            <span>{{ item.title }}</span>
            <span>{{ item.published_at | formatDate }}</span>
          </li>
        </ul>
        <p v-else>В данный момент информация отсутствует</p>
        <router-link :to="{ name: 'news' }" class="press-page__news-action btn btn--lg btn--outline">
          <span>Смотреть все</span>
          <IconComponent name="arrow-right" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BaseArticle from "components/UI/BaseArticle.vue";
import IconComponent from "components/IconComponent.vue";
export default {
  name: "PressNewsComponent",
  computed: {
    minister_news() {
      return this.$store.state.press.minister_news?.data;
    },
    department_news() {
      return this.$store.state.press.department_news?.data;
    },
    exception_news() {
      return this.$store.state.press.exception_news?.data;
    },
  },
  components: {
    BaseArticle,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.press-page {

  &__news {
    display flex
    flex-direction column
    gap 40px
  }

  &__news-cols {
    display grid
    grid-template-columns repeat(3, 1fr)
    gap 40px

    +below(900px) {
      grid-template-columns: 1fr
    }
  }

  &__news-col {
    display flex
    flex-direction column
    gap 30px
  }

  &__news-title {
    padding 20px 0 20px 0
    font-size: 1.375em
    font-weight: 700;
    line-height: 32px
    border-bottom 1px solid var(--gray-dark)
  }

  &__news-body {
    display flex
    flex-direction column
    grid-template-rows auto
    gap 15px
    overflow-x auto
    max-height 731px

    li {
      display flex
      flex-direction column
      background var(--white)
      padding 15px
      gap 10px
      font-size 1rem

      span:nth-child(2) {
        font-size: 0.750em
        font-style: normal
        font-weight: 400
        opacity 0.5
      }
    }
  }

  &__news-action {
    justify-self flex-end
    font-size 0.875rem

    .icon {
      width 12px
      height 12px
    }
  }
}
</style>
