<template>
  <div v-if="media_news && media_news.length" class="press-page__media">
    <h2>Медиа</h2>
    <!--    <BaseArticle :route="{ name: 'media' }" title="Медиа" />-->
    <ul class="press-page__media-items">
      <li v-for="(item, i) in media_news" :key="i">
        <CardComponent :item="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import CardComponent from "components/CardComponent.vue";
import BaseArticle from "components/UI/BaseArticle.vue";

export default {
  name: "PressMediaComponent",
  computed: {
    media_news() {
      return this.$store.state.press.media_news?.data;
    },
  },
  components: {
    BaseArticle,
    CardComponent,
  },
};
</script>

<style lang="stylus">
.press-page {

  .article__title {
    margin-left 0
  }

  &__media {
    display flex
    flex-direction column
    gap 40px
  }

  &__media-items {
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 40px

    +below(1300px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(900px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(600px) {
      gap 20px
    }
    +below(550px) {
      grid-template-columns repeat(1, 1fr)
    }
  }

}
</style>
