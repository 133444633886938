<template>
  <main class="press-page">
    <BreadcrumbsComponent title="Пресс-центр" :links="links" />
    <div class="container-padding">
      <div class="press-page__inner page__inner">
        <div class="press-page__filter filters">
          <SelectComponent
            v-model="form.type.value"
            :errors="form.type.errors"
            :options="news_types"
            label-name="title"
            title="Тип новости"
            placeholder="Все"
          />
          <InputComponent
            v-model.trim="form.title.value"
            :errors="form.title.errors"
            title="Поиск по заголовку"
          >
            <template v-slot:action>
              <IconComponent name="search-normal" />
            </template>
          </InputComponent>
          <DateComponent
            v-model="form.published_at.value"
            emptyPlaceholder="Любая"
            mode="date"
            range
            title="Дата публикации"
          />
          <router-link
            class="btn btn--lg btn--outline"
            :to="{
              name: 'news',
              query: {
                title: form.title.value,
                type: JSON.stringify(form.type.value),
                published_at: JSON.stringify(form.published_at.value),
              },
            }"
          >
            Поиск
          </router-link>
        </div>
        <PressDisputableComponent />
        <PressNewsComponent />
        <PressAnnounceComponent />
        <PressMediaComponent />
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import PressDisputableComponent from "./components/PressDisputableComponent.vue";
import PressNewsComponent from "./components/PressNewsComponent.vue";
import PressAnnounceComponent from "./components/PressAnnounceComponent.vue";
import PressMediaComponent from "./components/PressMediaComponent.vue";
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import DateComponent from "components/inputs/DateComponent.vue";
import PRESS_PAGE from "gql/pages/PressPage.graphql";

export default {
  name: "PressPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: PRESS_PAGE,
      })
      .then(({ data }) => {
        store.dispatch("press/save", data);
      })
      .catch(() => {});
  },
  data() {
    return {
      loading: false,
      form: {
        title: {
          value: undefined,
          errors: [],
        },
        published_at: {
          value: undefined,
          errors: [],
        },
        type: {
          value: [],
          defaultValue: [],
          errors: [],
        },
      },
    };
  },
  computed: {
    links() {
      return [
        {
          title: "Пресс-центр",
          to: { to: "press" },
        },
      ];
    },
    news_types() {
      return this.$store.state.press.government_news_types;
    },
  },
  components: {
    PressMediaComponent,
    PressNewsComponent,
    BreadcrumbsComponent,
    PressDisputableComponent,
    PressAnnounceComponent,
    IconComponent,
    InputComponent,
    SelectComponent,
    DateComponent,
  },
};
</script>

<style lang="stylus">

.press-page {
  padding-bottom 60px

  &__filter {
    grid-template-columns: 280fr 400fr 240fr 220fr;

    +below(1100px) {
      grid-template-columns 0.5fr 0.5fr
      grid-gap: 20px;
    }

    +below(620px) {
      grid-template-columns 1fr
    }
  }

  &__inner {
    display flex
    flex-direction column
    gap 60px
  }
  &__actions {
    display flex
    gap 15px
    align-items center
  }
}
</style>
