<template>
  <div v-if="discussed_news && discussed_news.length" class="press-page__disputable">
    <h2>Обсуждаемое</h2>
    <ul class="press-page__disputable-items">
      <li v-for="(item, i) in discussed_news" :key="i">
        <CardComponent :item="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import CardComponent from "components/CardComponent.vue";
export default {
  name: "PressDisputableComponent",
  computed: {
    discussed_news() {
      return this.$store.state.press.discussed_news?.data;
    },
  },
  components: {
    CardComponent,
  },
};
</script>

<style lang="stylus">
.press-page {

  &__disputable {
    display flex
    flex-direction column
    gap 40px
  }

  &__disputable-items {
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 40px

    +below(1300px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(900px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(600px) {
      gap 20px
    }
    +below(550px) {
      grid-template-columns repeat(1, 1fr)
    }
  }
}
</style>
