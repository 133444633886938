<template>
  <div v-if="announcements_news && announcements_news.length" class="press-page__announce">
    <BaseArticle
      title="Анонсы"
      :route="{
        name: 'news',
        query: { type: JSON.stringify({ id: 3, title: 'Анонсы', __typename: 'GovernmentNewsType' }) },
      }"
    />
    <ul class="press-page__announce-items">
      <li v-for="(item, i) in announcements_news" :key="i">
        <CardComponent :item="item" media="center" />
      </li>
    </ul>
  </div>
</template>

<script>
import BaseArticle from "components/UI/BaseArticle.vue";
import CardComponent from "components/CardComponent.vue";

export default {
  name: "PressAnnounceComponent",
  data() {
    return {
      items: [1, 2, 3, 4],
    };
  },
  computed: {
    announcements_news() {
      return this.$store.state.press.announcements_news?.data;
    },
  },
  components: {
    BaseArticle,
    CardComponent,
  },
};
</script>

<style lang="stylus">
.press-page {

  &__announce {
    display flex
    flex-direction column
    gap 40px
  }

  &__announce-items {
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 40px

    +below(1300px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(900px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(600px) {
      gap 20px
    }
    +below(550px) {
      grid-template-columns repeat(1, 1fr)
    }
  }

}
</style>
