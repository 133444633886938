<template>
  <div class="article">
    <!--    <div class="article__line"></div>-->
    <div class="article__content">
      <h2 v-if="title" :class="{ 'article__title--white': white }" class="article__title">
        {{ title }}
      </h2>
      <router-link v-if="route" :to="route" :class="{ 'article__link--white': white }" class="article__link">
        <span>Смотреть все</span>
        <IconComponent name="arrow-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "NewsSection",
  props: {
    title: String,
    white: Boolean,
    route: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.article {
  display flex
  gap 50px
  align-items center

  //&__line {
  //  width 136px
  //  height 2px
  //  background var(--main)
  //}

  &__title {
    margin-left 120px
    +below(700px) {
      margin-left 0
      font-size 1.375rem
    }

    &--white {
      color var(--white)
    }
  }

  &__content {
    display flex
    align-items center
    justify-content space-between
    width 100%
  }

  &__link {
    display flex
    align-items center
    gap 14px
    +below(1024px) {
      display none
    }

    &--white {
      span {
        color var(--white) !important
      }

      .icon svg path {
        fill var(--white) !important
      }
    }

    span {
      font-size .875rem
      line-height 22px
      color var(--main)
    }

    .icon svg path {
      fill var(--main)
    }
  }
}
</style>
