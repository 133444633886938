var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"press-page__news"},[_c('BaseArticle',{attrs:{"title":"Новости","route":{ name: 'news' }}}),_c('div',{staticClass:"press-page__news-cols"},[_c('div',{staticClass:"press-page__news-col"},[_c('div',{staticClass:"press-page__news-title"},[_vm._v("Председатель")]),(_vm.minister_news && _vm.minister_news.length)?_c('ul',{staticClass:"press-page__news-body"},_vm._l((_vm.minister_news),function(item,i){return _c('li',{key:i},[_c('span',[_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.published_at)))])])}),0):_c('p',[_vm._v("В данный момент информация отсутствует")]),_c('router-link',{staticClass:"press-page__news-action btn btn--lg btn--outline",attrs:{"to":{
          name: 'news',
          query: {
            type: JSON.stringify({ id: 1, title: 'Председатель', __typename: 'GovernmentNewsType' }),
          },
        }}},[_c('span',[_vm._v("Смотреть все")]),_c('IconComponent',{attrs:{"name":"arrow-right"}})],1)],1),_c('div',{staticClass:"press-page__news-col"},[_c('div',{staticClass:"press-page__news-title"},[_vm._v("Правительство")]),(_vm.department_news && _vm.department_news.length)?_c('ul',{staticClass:"press-page__news-body"},_vm._l((_vm.department_news),function(item,i){return _c('li',{key:i},[_c('span',[_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.published_at)))])])}),0):_c('p',[_vm._v("В данный момент информация отсутствует")]),_c('router-link',{staticClass:"press-page__news-action btn btn--lg btn--outline",attrs:{"to":{
          name: 'news',
          query: {
            type: JSON.stringify({ id: 2, title: 'Правительство', __typename: 'GovernmentNewsType' }),
          },
        }}},[_c('span',[_vm._v("Смотреть все")]),_c('IconComponent',{attrs:{"name":"arrow-right"}})],1)],1),_c('div',{staticClass:"press-page__news-col"},[_c('div',{staticClass:"press-page__news-title"},[_vm._v("Другие новости")]),(_vm.exception_news && _vm.exception_news.length)?_c('ul',{staticClass:"press-page__news-body"},_vm._l((_vm.exception_news),function(item,i){return _c('li',{key:i},[_c('span',[_vm._v(_vm._s(item.title))]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.published_at)))])])}),0):_c('p',[_vm._v("В данный момент информация отсутствует")]),_c('router-link',{staticClass:"press-page__news-action btn btn--lg btn--outline",attrs:{"to":{ name: 'news' }}},[_c('span',[_vm._v("Смотреть все")]),_c('IconComponent',{attrs:{"name":"arrow-right"}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }